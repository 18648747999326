import NavBar from "./components/NavBar/NavBar"
import { Link, RouteObject, useLocation, useNavigate } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import AppTheme from "./AppTheme";
import Loader from "./components/Loader/Loader";
import ProductPage from "./pages/DesignerPages/ProductPage/ProductPage"
import OptimizationsListPage from "./pages/DesignerPages/OptimizationPage/OptimizationsListPage";
import OptimizationPage from "./pages/DesignerPages/OptimizationPage/OptimizationPage";
import EmptyWorkspace from "./components/EmptyWorkspace/EmptyWorkspace";
import ReportsPage from "./pages/ManufacturerPages/ReportsPage/ReportsPage";
import AllReportsPage from "./pages/ManufacturerPages/ReportsPage/AllReportsPage";
import Window from "./components/Window/Window";
import { useDispatch } from "react-redux";
import { getRole } from "./api/RoleQueries";
import { setRoles, setSelectedRole } from "./components/NavBar/navBarSlice";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Alert from "./components/Alert/Alert";
import DesignersDashboardPage from "./pages/AdminPages/DesignersDashboardPage/DesignersDashboardPage";
import AllDesignersPage from "./pages/AdminPages/AllDesignersPage/AllDesignersPage";
import AllProductsPage from "./pages/AdminPages/AllProductsPage/AllProductsPage";
import AllOptimizationsPage from "./pages/AdminPages/AllOptimizationsPage/AllOptimizationsPage";
import ManufacturersDashboardPage from "./pages/AdminPages/ManufacturersDashboardPage/ManufacturersDashboardPage";
import AllManufacturersPage from "./pages/AdminPages/AllManufacturersPage/AllManufacturersPage";
import AllReportsAdminPage from "./pages/AdminPages/AllReportsAdminPage/AllReportsAdminPage";
import SpliceSitePredictorPage from "./pages/DesignerPages/SpliceSitePredictorPage/SpliceSitePredictorPage";
import CreateRunPage from "./pages/AdminPages/CreateRunPage/CreateRunPage";
import ProcessPage from "./pages/ManufacturerPages/ProcessPage/ProcessPage";
import AdminHomePage from "./pages/AdminPages/AdminHomePage/AdminHomePage";
import DesignerHomePage from "./pages/DesignerPages/DesignerHomePage/DesignerHomePage";
import ManufacturerHomePage from "./pages/ManufacturerPages/ManufacturerHomePage/ManufacturerHomePage";
import TemplatesPage from "./pages/TemplatesPage/TemplatesPage";
import AllTemplatesPage from "./pages/TemplatesPage/AllTemplatesPage";
import ReservedRunsPage from "./pages/ManufacturerPages/ReservedRunsPage/ReservedRunsPage";
import { getPathByRole } from "./utils/path";


const ProtectedProductPage = withAuthenticationRequired(ProductPage);
const ProtectedEmptyWorkspace = withAuthenticationRequired(EmptyWorkspace);
const ProtectedOptimizationsListPage = withAuthenticationRequired(OptimizationsListPage);
const ProtectedOptimizationPage = withAuthenticationRequired(OptimizationPage);
const ProtectedReportsPage = withAuthenticationRequired(ReportsPage);
const ProtectedAllReportsPage = withAuthenticationRequired(AllReportsPage);
const ProtectedDesignersDashboardPage = withAuthenticationRequired(DesignersDashboardPage);
const ProtectedAllDesignersPage = withAuthenticationRequired(AllDesignersPage);
const ProtectedAllProductsPage = withAuthenticationRequired(AllProductsPage);
const ProtectedAllOptimizationsPage = withAuthenticationRequired(AllOptimizationsPage);
const ProtectedManufacturersDashboardPage = withAuthenticationRequired(ManufacturersDashboardPage);
const ProtectedAllManufacturersPage = withAuthenticationRequired(AllManufacturersPage);
const ProtectedAllReportsAdminPage = withAuthenticationRequired(AllReportsAdminPage);
const ProtectedSpliceSitePredictorPage = withAuthenticationRequired(SpliceSitePredictorPage);
const ProtectedCreateRunPage = withAuthenticationRequired(CreateRunPage);
const ProtectedProcessPage = withAuthenticationRequired(ProcessPage);
const ProtectedAdminHomePage = withAuthenticationRequired(AdminHomePage);
const ProtectedDesignerHomePage = withAuthenticationRequired(DesignerHomePage);
const ProtectedManufacturerHomePage = withAuthenticationRequired(ManufacturerHomePage);
const ProtectedTemplatesPage = withAuthenticationRequired(TemplatesPage);
const ProtectedAllTemplatesPage = withAuthenticationRequired(AllTemplatesPage);
const ProtectedReservedRunsPage = withAuthenticationRequired(ReservedRunsPage);

interface RouteObjectWithRole extends RouteObject {
  path: string
  role: string | undefined
}

const routes: RouteObjectWithRole[] = [
  {
    path: "/",
    element: <ProtectedEmptyWorkspace />,
    role: undefined
  },
  {
    path: "/constructs",
    element: <ProtectedProductPage />,
    role: 'designer'
  },
  {
    path: "/constructs/:id",
    element: <ProtectedProductPage />,
    role: 'designer'
  },
  // {
  //   path: '/optimization/new',
  //   element: <ProtectedOptimizationPage />,
  //   role: 'designer'
  // },
  // {
  //   path: "/optimization",
  //   element: <ProtectedOptimizationsListPage />,
  //   role: 'designer'
  // },
  // {
  //   path: "/optimization/:id",
  //   element: <ProtectedOptimizationPage />,
  //   role: 'designer'
  // },
  {
    path: "/reports",
    element: <ProtectedAllReportsPage />,
    role: 'manufacturer'
  },
  {
    path: "/reports/:id",
    element: <ProtectedReportsPage />,
    role: 'manufacturer'
  },
  {
    path: "/broker",
    element: <ProtectedAdminHomePage />,
    role: 'admin'
  },
  {
    path: "/reports/create",
    element: <ProtectedReportsPage />,
    role: 'manufacturer'
  },
  {
    path: "/broker-designers-dashboard",
    element: <ProtectedDesignersDashboardPage />,
    role: 'admin'
  },
  {
    path: "/broker-designers",
    element: <ProtectedAllDesignersPage />,
    role: 'admin'
  },
  {
    path: "/broker-constructs",
    element: <ProtectedAllProductsPage />,
    role: 'admin'
  },
  // {
  //   path: "/admin-optimizations",
  //   element: <ProtectedAllOptimizationsPage />,
  //   role: 'admin'
  // },
  {
    path: "/broker-manufacturers-dashboard",
    element: <ProtectedManufacturersDashboardPage />,
    role: 'admin'
  },
  {
    path: "/broker-manufacturers",
    element: <ProtectedAllManufacturersPage />,
    role: 'admin'
  },
  {
    path: "/broker-reports",
    element: <ProtectedAllReportsAdminPage />,
    role: 'admin'
  },
  {
    path: "/splice-site-predictor",
    element: <ProtectedSpliceSitePredictorPage />,
    role: 'designer'
  },
  {
    path: "/create-run",
    element: <ProtectedCreateRunPage />,
    role: 'admin'
  },
  {
    path: "/process/:id",
    element: <ProtectedProcessPage />,
    role: 'manufacturer'
  },
  {
    path: "/designer",
    element: <ProtectedDesignerHomePage />,
    role: 'designer'
  },
  {
    path: "/manufacturer",
    element: <ProtectedManufacturerHomePage />,
    role: 'manufacturer'
  },
  {
    path: "/templates",
    element: <ProtectedAllTemplatesPage />,
    role: 'manufacturer'
  },
  {
    path: "/templates/:id",
    element: <ProtectedTemplatesPage />,
    role: 'manufacturer'
  },
  {
    path: "/published-runs",
    element: <ProtectedReservedRunsPage />,
    role: 'manufacturer'
  }
];

function App() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [display404, setDisplay404] = useState(false);
  const [currentRole, setCurrentRole] = useState("")
  const [roles, setCurrRoles] = useState<any>(undefined)
  const [rolesNoAdmin, setRolesNoAdmin] = useState<any>(undefined)
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (roles) {
      checkPath()
    }
  }, [location, roles])

  const checkPath = () => {
    if (location.pathname === '/') {
      const name = rolesNoAdmin[0].split(":")[1]
      navigate(getPathByRole(name));
      setCurrentRole(name)
      dispatch(setSelectedRole(rolesNoAdmin[0]))
    }
    else {
      const findRouteObj = routes.find((routeObj: RouteObjectWithRole) => {
        if (routeObj.path) {
          if (routeObj.path.includes(":id")) {
            const root = routeObj.path.split("/")[1]
            const pattern = new RegExp(`^\\/${root}(\\/[^\\/]+)?$`);
            return pattern.test(location.pathname)
          }
          else {
            return routeObj.path === location.pathname || location.pathname === routeObj.path + "/"
          }

        }
      });

      const name = roles[0].split(":")[1]
      if (findRouteObj) {
        if (findRouteObj.role) {
          const matchRole = roles.find((role: any) => role.includes(findRouteObj.role))
          if (matchRole) {
            dispatch(setSelectedRole(matchRole))
            setCurrentRole(matchRole.split(":")[1])
          }
          else {
            navigate(getPathByRole(name))
            dispatch(setSelectedRole(roles[0]))
            setCurrentRole(name)
          }
        }
        // special case of no role is the home route, simply dispatch the default role
        else {
          dispatch(setSelectedRole(roles[0]))
          setCurrentRole(name)
        }
      }
      else {
        setDisplay404(true)
        dispatch(setSelectedRole(roles[0]))
        setCurrentRole(name)
      }
    }
  }



  const getAllRoles = async () => {
    const accessToken = await getAccessTokenSilently()
    const result = await getRole(accessToken)
    if (result.roles.length > 0) {
      const rolesBesidesAdmin = result.roles.filter((role: string) => role !== 'manage:admin')
      const adminRole = result.roles.find((role: string) => role === 'manage:admin')
      const manufRole = result.roles.find((role: string) => role === 'manage:manufacturer')
      const designerRole = result.roles.find((role: string) => role === 'manage:designer')
      const roles = [adminRole, manufRole, designerRole].filter((role: string) => role != undefined)
      setCurrRoles(roles)
      setRolesNoAdmin(rolesBesidesAdmin)

      if (rolesBesidesAdmin.length === 0) {
        navigate('/broker')
        dispatch(setSelectedRole('manage:admin'))
        setCurrentRole('admin')
      }

    }
    dispatch(setRoles(result.roles))
  }

  useEffect(() => {
    if (!isLoading) {
      getAllRoles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <ThemeProvider theme={AppTheme}>
        <NavBar />
        {display404 ?
          <Box className="box-overflow" sx={{ textAlign: 'center' }}>
            <Typography textAlign='center' variant="h5" sx={{ mt: 5 }}>
              404 Page Not Found</Typography>
            <Link to={`/${currentRole}`}><Button sx={{ mt: 5 }} onClick={() => setDisplay404(false)} variant='contained'>Navigate Home</Button></Link>
          </Box>
          :
          <Window routes={routes} />
        }

        <Alert />
      </ThemeProvider>
    </>
  );
}

export default App;
