import { Box, IconButton, ListItem, ListItemButton, ListItemText, Modal, Stack, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { Product } from "../../models/Product"
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";

interface ProductItemProps {
  product: Product
  onDelete: () => void
}

const ProductItem = ({ product, onDelete }: ProductItemProps) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const selectProduct = (id: string | undefined) => {
    if (id) {
      navigate(`/constructs/${id}`)
    }
  }
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 10,
    borderRadius: "16px",
    p: 6
  };

  const handleDelete = () => {
    setDeleting(true)
    onDelete()
    setDeleting(false)
    setDeleteModal(false)
  }

  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={product.id === id}
        onClick={() => selectProduct(product.id)}
      >
        <ListItemText primary={
          <Typography sx={{ ml: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" color={product.id === '' ? 'primary.main' : 'white'} >
            {product.name}
          </Typography>} />
        {product?.runIsReserved ?
          <IconButton size="small">
            <CheckCircleIcon fontSize="small" color="success" />
          </IconButton>
          :
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModal(true)
            }}
          >
            {product.run?.status === 'PUBLISHED' ?
              <DeleteIcon fontSize="small" /> :
              <WarningIcon fontSize="small" color='warning' />
            }
          </IconButton>


        }
      </ListItemButton>
      {deleteModal && (
        <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
          <Box sx={style}>
            <Stack direction='column' alignItems='center' spacing={5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems='start'
                sx={{ width: '100%' }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                  {product.run?.status === 'PUBLISHED' ?
                    `Are you sure you want to delete ${product.name}?` :
                    `The run associated with this product is no longer available and you won't be
                    able to reserve it. Do you want to delete ${product.name}?`
                  }
                </Typography>
                <IconButton onClick={() => setDeleteModal(false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <LoadingButton
                loading={deleting}
                variant='contained'
                color='error'
                onClick={handleDelete}
              >
                Delete
              </LoadingButton>
            </Stack>
          </Box>
        </Modal>
      )}
    </ListItem>
  )
}

export default ProductItem
