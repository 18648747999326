import { Box, Grid, Stack, Typography } from "@mui/material"
import ProfilePage from "../ProfilePage/ProfilePage"
import RunsDash from "../RunsPage/RunsDash"

function DesignerHomePage() {
    return (
        <Box justifyContent='center' sx={{ mb: 10 }}>
            <Typography textAlign='center' variant="h5" sx={{ mt: 5, mb: 2}}>
                Designer Home</Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ProfilePage />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6" textAlign="center" sx={{ mt: 5, ml: 5 }}>Run Dashboard</Typography>
                    <RunsDash />
                </Grid>
            </Grid>
        </Box>
    )
}

export default DesignerHomePage