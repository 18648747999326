import {
  AssessmentOutlined,
  ConstructionOutlined,
  DesignServicesOutlined,
  FactoryOutlined,
  ScienceOutlined,
  LocationSearchingOutlined,
  HomeOutlined,
  ExtensionOutlined,
  PrecisionManufacturingOutlined
} from "@mui/icons-material"
import { SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { Roles } from "../models/UserPreferences"


export interface BranchProps {
  label: string
  value: string,
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string },
  href: string
}

export default function toolbarTreeByRole(role: Roles | null): Array<BranchProps> {
  switch (role) {
    case 'manage:admin':
      return [
        {
          label: 'Home',
          value: 'broker-home',
          Icon: HomeOutlined,
          href: '/broker'
        },
        {
          label: 'Designers',
          value: 'designers',
          Icon: ScienceOutlined,
          href: '/broker-designers-dashboard'
        },
        {
          label: 'Manufacturers',
          value: 'manufacturers',
          Icon: FactoryOutlined,
          href: '/broker-manufacturers-dashboard'
        }
      ]
    case 'manage:designer':
      return [
        {
          label: 'Home',
          value: 'designer-home',
          Icon: HomeOutlined,
          href: '/designer'
        },
        {
          label: 'Constructs',
          value: 'constructs',
          Icon: DesignServicesOutlined,
          href: '/constructs'
        },
        // {
        //   label: 'Codon Optimizer',
        //   value: 'optimization',
        //   Icon: ConstructionOutlined,
        //   href: '/optimization'
        // },
        {
          label: 'Splice site predictor',
          value: 'splice-site-predictor',
          Icon: LocationSearchingOutlined,
          href: '/splice-site-predictor'
        }
      ]
    case 'manage:manufacturer':
      return [
        {
          label: 'Home',
          value: 'manufacturer-home',
          Icon: HomeOutlined,
          href: '/manufacturer'
        },
        {
          label: 'Templates',
          value: 'templates',
          Icon: ExtensionOutlined,
          href: '/templates'
        },
        {
          label: 'Published runs',
          value: 'published-runs',
          Icon: PrecisionManufacturingOutlined,
          href: '/published-runs'
        },
        {
          label: 'Reports',
          value: 'reports',
          Icon: AssessmentOutlined,
          href: '/reports'
        },
      ]
    default:
      return []
  }

}
