import { useAuth0 } from "@auth0/auth0-react";
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct, setProduct } from "../ProductsSlice";
import { updateProduct } from "../../../api/ProductQueries";
import { Product } from "../../../models/Product";
import './ProductGeneralStep.css'

interface ProductGeneralStepProps {
    setUpdateSideBar?: (update: boolean) => void
}

interface Quantity {
    value: string
    label: {
        value: number,
        scale: string
    }
}
const DEFAULT_QUANTITIES = [
    {
        value: "200ug",
        label: {
            value: 200,
            scale: "\u00B5g"
        }
    },
    {
        value: "10mg",
        label: {
            value: 10,
            scale: "mg"
        }
    },
    {
        value: "1mg",
        label: {
            value: 1,
            scale: "mg"
        }
    }
] as Array<Quantity>

const ProductGeneralStep = ({ setUpdateSideBar }: ProductGeneralStepProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatchGlobal = useDispatch()

    const { id } = useParams()

    const product = useSelector(selectProduct)

    useEffect(() => {
        if (product) {
            setProductGeneralInfo({
                name: product?.name,
                description: product?.description,
                quantity: product?.quantity
            })
        }
    }, [product])

    const [productGeneralInfo, setProductGeneralInfo] = useState({
        name: product?.name,
        description: product?.description,
        quantity: product?.quantity
    })

    const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

    const handleInputChange = (field: string, value: string | undefined) => {

        setProductGeneralInfo((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));

        if (debounceTimeout !== null) clearTimeout(debounceTimeout);

        const newDebounceTimeout = setTimeout(async () => {
            await updateProductQuery({ ...productGeneralInfo, [field]: value });
        }, 500);

        setDebounceTimeout(newDebounceTimeout);
    };

    useEffect(() => {
        if (id && product?.id === id) {
            setProductGeneralInfo({
                name: product?.name,
                description: product?.description,
                quantity: product?.quantity
            })
        }
    }, [id, product?.id])

    const updateProductQuery = async (partialProduct: Partial<Product>) => {
        if (id && setUpdateSideBar) {
            const accessToken = await getAccessTokenSilently()
            const updatedProduct = await updateProduct(accessToken, partialProduct, id)
            if (updatedProduct) dispatchGlobal(setProduct(updatedProduct))

            if (partialProduct.name !== product?.name) {
                setUpdateSideBar(true)
            }
        }
    }

    const quantityOptions = useMemo(() => {
        return DEFAULT_QUANTITIES.map((quantity) => (
            <MenuItem key={quantity.value} value={quantity.value}>
                {quantity.label.value}<b>{quantity.label.scale}</b>
            </MenuItem>
        ))
    }, [DEFAULT_QUANTITIES])

    return (
        <Stack direction='column' spacing={8} alignItems='center' sx={{ width: '100%', pb: 2 }}>
            <TextField
                label='Construct name'
                size="small"
                autoComplete="off"
                required={true}
                sx={{ width: '51%' }}
                value={productGeneralInfo.name ?? ''}
                disabled={product?.runIsReserved}
                onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <TextField
                label='Construct description'
                size="small"
                autoComplete="off"
                multiline
                rows={4}
                sx={{ width: '51%' }}
                value={productGeneralInfo.description ?? ''}
                disabled={product?.runIsReserved}
                onChange={(e) => handleInputChange("description", e.target.value)}
            />
            <FormControl sx={{ minWidth: '51%', m: 1 }} size="small">
                <InputLabel id="quantity-label">Quantity</InputLabel>
                <Select
                    labelId="quantity-label"
                    label='Quantity'
                    size="small"
                    autoWidth
                    disabled={product?.runIsReserved}
                    value={productGeneralInfo.quantity ?? ''}
                    onChange={(e) => handleInputChange("quantity", e.target.value)}
                >
                    {quantityOptions}
                </Select>
            </FormControl>
        </Stack>
    )
}

export default ProductGeneralStep
