export function getComponentDisplayName(componentName: string) {

    return componentName
}

const constantAndDisplayNames = {
    // Reagents
    'NEB': 'NEB',
    'THERMOFISHER': 'Thermofisher',
    'IN_HOUSE_BUFFERS': 'In-house buffers',
    'TFS': 'TFS',
    'JENA': 'JENA',
    'IN_HOUSE_ENZYME': 'In-house production (enzyme)',
    'ROCHE': 'Roche',

    //Kits
    'IVT_KIT': 'IVT Kit',
    'KIT_FREE': 'Kit free',

    //Purification methods
    'TFF': 'TFF',
    'CHROMATOGRAPHY': 'Chromatography',

    //Mode of operation
    'FLOW': 'Flow',
    'FED_BATCH': 'Fed Batch',
    'REPEAT_BATCH': 'Repeat Batch',
    'BATCH': 'Batch',


    //Run output
    'PreAmplificatonDNAIntermediate': 'Pre Amplificaton DNA Intermediate',
    'PrePurificationDNAIntermediate': 'Pre Purification DNA Intermediate',
    'PurifiedDNAIntermediate': 'Purified DNA Intermediate',
    'PrePurificationRNAIntermediate': 'Pre Purification RNA Intermediate',
    'PurifiedRNADrugSubstance': 'Purified RNA (Drug Substance)',
    'PrePurificationFormulatedRNAIntermediate': 'Pre Purification Formulated RNA Intermediate',
    'PurifiedFormulatedRNADrugProduct': 'Purified Formulated RNA (Drug Product)',

    //Product prupose:
    'PROTEIN_EXPRESSION': 'Protein expression',
    'STRUCTURAL_RESEARCH': 'Structural research',
    'OTHER': 'Other',

    //Product type
    'mRNA': 'mRNA',
    'SELF_AMPLIFYING': 'Self-amplifying',
    'CIRCULAR': 'Circular',
    'siRNA': 'siRNA',

    // Plasmid type
    'LINEAR': 'Linear',

    'DRUG_SUBSTANCE': 'Purified Drug Substance',
    'FORMULATED_DRUG_PRODUCT': 'Formulated Drug Product',

    "PUBLISHED": "Published",
    "DRAFTED": "Drafted",
    "QUEUED": "Queued",
    "RESERVED": "Reserved",
    "IN_PROGRESS": "In Progress",
    "SHIPPING_TO_BIOFORGE": "Shipping to Bioforge",
    "BIOFORGE_QC": "Bioforge QC",
    "SHIPPING_TO_DESIGNER": "Shipping to Designer",
    "COMPLETED": "Completed",
    "CANCELED": "Canceled",
    "ARCHIVED": "Archived"

}


export const getConstantDisplayName = (constant: string | undefined) => {
    if (!constant) return '-'
    return constantAndDisplayNames[constant as keyof typeof constantAndDisplayNames]
}



