import { Box, Typography } from "@mui/material"
import BrokerDashboardPage from "../BrokersDashboardPage/BrokersDashboardPage"

function AdminHomePage() {

    return (
        <Box justifyContent='center'>
            <Typography textAlign='center' variant="h5" sx={{ mt: 5 }}>Broker Home</Typography>
            <Typography sx={{ textAlign: 'center', mt: 5 }} variant="subtitle1">Run dashboard</Typography>
            <BrokerDashboardPage />
        </Box>
    )
}

export default AdminHomePage
