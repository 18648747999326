import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct, setProduct } from "../ProductsSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { Product } from "../../../models/Product";
import { updateProduct } from "../../../api/ProductQueries";

const ProductDrugProductStep = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatchGlobal = useDispatch()

    const { id } = useParams()

    const product = useSelector(selectProduct)

    const [drugProductInfo, setDrugProductInfo] = useState({
        formulation: product?.formulation,
        formulationDetails: product?.formulationDetails,
        formulationDetailsDesigner: product?.formulationDetailsDesigner,
    })

    const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

    const handleInputChange = (field: string, value: string) => {

        setDrugProductInfo((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));

        if (debounceTimeout !== null) clearTimeout(debounceTimeout);

        const newDebounceTimeout = setTimeout(async () => {
            await updateProductQuery({ ...drugProductInfo, [field]: value });
        }, 500);

        setDebounceTimeout(newDebounceTimeout);
    };


    const updateProductQuery = async (partialProduct: Partial<Product>) => {
        if (id) {
            const accessToken = await getAccessTokenSilently()
            const updatedProduct = await updateProduct(accessToken, partialProduct, id)
            if (updatedProduct) dispatchGlobal(setProduct(updatedProduct))
        }
    }

    useEffect(() => {
        if (id && product?.id === id) {
            setDrugProductInfo({
                formulation: product?.formulation,
                formulationDetails: product?.formulationDetails,
                formulationDetailsDesigner: product?.formulationDetailsDesigner,
            })
        }
    }, [id, product?.id])

    return (
        <Stack direction='column' spacing={8} alignItems='center' sx={{ width: '50%' }}>

            <Typography variant='body1'>Formulation: {drugProductInfo.formulation ? "Yes" : "Not available"}</Typography>
            {drugProductInfo.formulation &&
                <Typography variant='body1'>
                    Formulation Details: {drugProductInfo.formulationDetails ?? "Not provided"}
                </Typography>
            }
            {drugProductInfo.formulation &&
                <TextField
                    sx={{ width: '100%' }}
                    multiline
                    minRows={3}
                    label="Formulation comments"
                    size="small"
                    variant="outlined"
                    autoComplete="off"
                    type='number'
                    disabled={product?.runIsReserved}
                    value={drugProductInfo.formulationDetailsDesigner ?? ''}
                    onChange={(e) => handleInputChange("formulationDetailsDesigner", e.target.value)}
                />
            }
        </Stack>
    )
}

export default ProductDrugProductStep
