import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Stack,
  Modal,
  Select,
  FormControl,
  MenuItem,
  Button,
  IconButton,
  Grid,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import { Run, RunStatus } from "../../../models/Run";
import { deleteRun, updateRun } from "../../../api/RunQueries";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo, useState } from "react";
import { getConstantDisplayName } from "../../../utils/getDisplayNames";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const runCardStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 10,
  borderRadius: "16px",
  p: 6,
  maxHeight: '80%',
  overflowY: "auto"
};

export const runStatusColors: Record<RunStatus, string> = {
  PUBLISHED: "#00A5D8",
  DRAFTED: "#D6B85A",
  QUEUED: "blue",
  RESERVED: "red",
  IN_PROGRESS: "orange",
  SHIPPING_TO_BIOFORGE: "#046f7d",
  BIOFORGE_QC: "#fc03b1",
  SHIPPING_TO_DESIGNER: "#ab5000",
  COMPLETED: "green",
  CANCELED: "gray",
  ARCHIVED: "purple",
};

const getFormattedDate = (date: string) => {
  const convertedDate = new Date(date);
  return convertedDate.toLocaleDateString("en-US");
};

const checkExpiration = (date: Date) => {
  const runDate = new Date(date)
  return runDate < new Date()
}

interface RunCardDetailsProps {
  run: Run;
  forManufacturers?: boolean;
}

export function RunCardDetails({ run, forManufacturers }: RunCardDetailsProps) {

  const dnaSynthesisText = useMemo(() => (dnaSynthesis: boolean, forManufacturers: boolean | undefined) => {
    if (forManufacturers) {
      return dnaSynthesis ? "This run requires DNA synthesis.  The designer will not be providing a DNA sample." : "This run does not require DNA synthesis.  The designer will provide a DNA sample."
    }
    return dnaSynthesis ? "Yes - A DNA sample will not be provided to the vendor" : "No -  A DNA sample will be provided to the vendor."
  }, [])

  return (
    <>
      <Typography
        sx={{ fontSize: 14, fontWeight: 16 }}

        gutterBottom
      >
        Name: {run.name}
      </Typography>
      <Typography
        sx={{ fontSize: 14, fontWeight: 16 }}
        gutterBottom
      >
        Description: {run.description}
      </Typography>
      {!forManufacturers && run.manufacturer &&
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          Manufacturer: {run.manufacturer.institutionName ?? "Not available"}
        </Typography>
      }
      {forManufacturers && run.designer &&
        <>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            Designer: {run.designer.firstName + " " + run.designer.lastName}
          </Typography>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            Designer institution: {run.designer.institution ?? "Not available"}
          </Typography>
        </>
      }
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Process: {run.process?.name ?? "None"}
      </Typography>
      {run.process?.description &&
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          Process description: {run.process.description}
        </Typography>
      }
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Base cost: ${run.baseCost.toFixed(2)} (USD)
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Date to submit by: {getFormattedDate(String(run.dateToSubmit))}
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Shipping date: {getFormattedDate(String(run.dateToShip))}
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        DNA synthesis: {dnaSynthesisText(run.dnaSynthesis, forManufacturers)}
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Formulation: {run.formulation ? "Yes" : "No"}
      </Typography>
      {run.formulation &&
        <Typography sx={{ fontSize: 14 }}>
          Formulation details: {run.formulationDetails ?? "Not provided"}
        </Typography>
      }
    </>
  )

}

const menuStyles = {
  chipWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  chip: {
    color: "white",
    cursor: "pointer",
  },
};

interface RunStatusChipProps {
  status: RunStatus;
  notClickable?: boolean;
}

function RunStatusChip({ status, notClickable }: RunStatusChipProps) {
  return (
    <div style={menuStyles.chipWrapper}>
      <Chip
        style={notClickable ? { color: 'white' } : menuStyles.chip}
        sx={{ backgroundColor: runStatusColors[status] }}
        label={getConstantDisplayName(status)}
      />
    </div>
  );
}

interface RunCardProps {
  currentRun: Run;
  runs: Run[];
  setRuns: (runs: Run[]) => void;
  forManufacturers?: boolean;
  onViewProduct?: (productId: string) => void;
}

function RunCard({ currentRun, runs, setRuns, forManufacturers, onViewProduct }: RunCardProps) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deletingId, setDeletingId] = useState<string | undefined>();

  const { getAccessTokenSilently } = useAuth0();

  const handleUpdateRun = async (status: RunStatus) => {
    if (currentRun?.id) {
      const accessToken = await getAccessTokenSilently();
      const partialRun = { status }
      const updatedRun = await updateRun(accessToken, partialRun, currentRun.id);
      if (updatedRun) {
        const newRuns = runs.map((run) => {
          if (run.id === currentRun.id) {
            return updatedRun;
          }
          return run;
        });
        setRuns(newRuns);
      }
    }
  };

  const handleDeleteRun = async () => {
    if (currentRun?.id) {
      setDeletingId(currentRun.id);
      const accessToken = await getAccessTokenSilently();
      const deletedRun = await deleteRun(accessToken, currentRun.id);
      if (deletedRun) {
        const newRuns = runs.filter((run) => run.id !== currentRun.id);
        setRuns(newRuns);
      }
      setDeleteModal(false);
      setDeletingId(undefined);
    }
  };

  const runStatusMenuItems = useMemo(() => {
    return Object.values(RunStatus).map(
      (status) => {
        return (
          <MenuItem value={status}>
            <RunStatusChip status={status} />
          </MenuItem>
        )
      })
  }, []);


  return (
    <Card
      sx={{
        height: 400,
        m: 2,
        pt: 0,
        pr: 2,
        pl: 2,
        borderRadius: "16px"
      }}
    >
      <CardContent sx={{ height: '85%' }}>
        <Stack direction="column" spacing={0} alignItems='center' justifyContent='space-between' sx={{ height: '100%' }}>
          <Grid container direction="row" columnSpacing={2} alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
            <Grid item>
              {forManufacturers ?
                <RunStatusChip status={currentRun.status} notClickable />
                :
                <FormControl>
                  <Select
                    disableUnderline={true}
                    value={currentRun.status}
                    label="Run Status"
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    onChange={(e: any) => {
                      handleUpdateRun(e.target.value);
                    }}
                  >
                    {runStatusMenuItems}
                  </Select>
                </FormControl>
              }
            </Grid>
            {!forManufacturers &&
              <Grid item>
                <DeleteIcon
                  onClick={() => setDeleteModal(true)}
                  sx={{ ":hover": { cursor: "pointer" } }}
                />
              </Grid>
            }
          </Grid>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 16,
              color: "text.primary",
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {currentRun.name}
          </Typography>
          {checkExpiration(currentRun.dateToSubmit) && currentRun.status === 'PUBLISHED' &&
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 16,
                mt: 1,
                overflow: 'hidden',
                width: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3
              }}
              color='error'
            >
              This run has expired and will not be shown to designers
            </Typography>
          }
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 16,
              mt: 1,
              overflow: 'hidden',
              width: '100%',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {currentRun.description}
          </Typography>
          <Stack direction="column" sx={{ mt: 3 }}>
            <Button variant="outlined" size="small" onClick={() => setViewDetails(true)}>View Details</Button>
            {!forManufacturers && currentRun.cqaReport &&
              <Link to={`/reports/${currentRun.cqaReport.id}`}>
                <Button sx={{ mt: 1 }} variant="outlined" size="small">View CQA Report</Button>
              </Link>
            }
            {forManufacturers && currentRun.finalProductId &&
              <Button sx={{ mt: 1 }} variant="outlined" size="small" onClick={() => onViewProduct!(currentRun.finalProductId!)}>View construct</Button>
            }
            {forManufacturers && currentRun.cqaReport &&
              <Link to={`/reports/${currentRun.cqaReport.id}`}>
                <Button sx={{ mt: 1 }} variant="outlined" size="small">View CQA Report</Button>
              </Link>
            }
            {forManufacturers && !currentRun.cqaReport && currentRun.finalProductId &&
              <Link to={`/reports/create`} state={{ currentRunId: currentRun.id }}>
                <Button sx={{ mt: 1 }} variant="contained" size="small">Submit CQA Report</Button>
              </Link>
            }
          </Stack>
        </Stack>
      </CardContent>
      {viewDetails && (
        <Modal open={viewDetails} onClose={() => setViewDetails(false)}>
          <Box sx={runCardStyle}>
            <Typography
              sx={{ fontSize: 18, fontWeight: 16, mb: 2 }}
              color="text.primary"
              gutterBottom
            >
              Run Details
            </Typography>
            <IconButton sx={{ position: 'absolute', top: '30px', right: '30px' }} onClick={() => setViewDetails(false)}>
              <CloseIcon />
            </IconButton>
            <RunCardDetails run={currentRun} forManufacturers={forManufacturers} />
          </Box>
        </Modal>
      )}
      {deleteModal && (
        <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
          <Box sx={runCardStyle}>
            <Stack direction='column' alignItems='center' spacing={5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems='start'
                sx={{ width: '100%' }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                  Are you sure you want to delete {currentRun.name}?
                </Typography>
                <IconButton onClick={() => setDeleteModal(false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <LoadingButton
                variant='contained'
                color='error'
                loading={!!deletingId && deletingId === currentRun.id}
                onClick={handleDeleteRun}
              >
                Delete
              </LoadingButton>
            </Stack>
          </Box>
        </Modal>
      )}
    </Card>
  );
}

export default RunCard;
