import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Stack, Box, Tabs, Tab } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { Manufacturer } from "../../models/Manufacturer"
import { getManufacturers } from "../../api/ManufacturerQueries";
import { analyzeSequence } from "../../api/ManufacturabilityQueries";

import { Evaluation } from "../../models/Breach";
import { useSelector } from "react-redux";
import { selectProduct } from "../ProductWindow/ProductsSlice";
import { useParams } from "react-router-dom";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface ManufacturabilityCheckerProps {
    highlightSequence: (range: string | null) => void;
    constructHasTBDcomponent?: boolean;
}

function ManufacturabilityChecker(props: ManufacturabilityCheckerProps) {
    const id = useParams()
    const product = useSelector(selectProduct)
    const { getAccessTokenSilently } = useAuth0();

    const [selectedTab, setSelectedTab] = useState(0);
    const [evaluatingSequence, setEvaluatingSequence] = useState(false);
    const [evaluationResult, setEvaluationResult] = useState<Array<Evaluation>>();
    const [clickThrough, setClickThrough] = useState<number | null>(null);
    const [clickIndex, setClickIndex] = useState<number | null>(null)
    const [sequenceUnchanged, setSequenceUnchanged] = useState(false);

    const fetchManufacturers = async () => {
        try {
            const accessToken = await getAccessTokenSilently()
            const manufacturers: Manufacturer[] = await getManufacturers(accessToken)
            return manufacturers
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchManufacturers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setSequenceUnchanged(false)
    }, [product])

    useEffect(() => {
        if (product) {
            setEvaluationResult(undefined)
            setEvaluatingSequence(false)
        }
    }, [id])

    const evaluateSequence = async () => {
        setEvaluatingSequence(true)
        setSequenceUnchanged(true)
        try {
            if (product) {
                const accessToken = await getAccessTokenSilently()
                let fullSequence = product.fullSequence
                if (!fullSequence) {
                    fullSequence = product.components.reduce((acc, curr) => {
                        return acc + curr.sequence
                    }, "")
                }
                const result = await analyzeSequence(accessToken, fullSequence)
                if (result) {
                    const evaluation = Object.entries(result)
                        .filter(([_, value]) => value !== '')
                        .map((arr) => ({ breach: arr.at(0), position: arr.at(1) }))

                    const sortedEvaluations = sortEvaluations(evaluation)

                    setEvaluationResult(sortedEvaluations)
                }
            }
        } catch (e) {
            throw e
        }
        setEvaluatingSequence(false)
    }

    const sortEvaluations = (evaluations: Array<Evaluation>) => {
        const cutsites = []
        const gcs = []
        const homologies = []
        const repeats = []
        const hairpins = []

        // cutsites, gc, homology repeats
        for (let evaluation of evaluations) {
            if (evaluation.breach?.includes('GC')) {
                gcs.push(evaluation)
            } else if (evaluation.breach?.includes('homologies')) {
                homologies.push(evaluation)
            } else if (evaluation.breach?.includes('repeats')) {
                repeats.push(evaluation)
            } else if (evaluation.breach?.includes('hairpin')) {
                hairpins.push(evaluation)
            } else {
                cutsites.push(evaluation)
            }
        }

        return [...cutsites, ...gcs, ...homologies, ...repeats, ...hairpins]
    }

    const canCheckSequence = useMemo(() => {
        if (product) {
            if (product.fullSequence) return true
            for (const component of product.components) {
                const correspondingTemplateComponent = product.run!.process!.template!.components.find(templateComponent => templateComponent.name === component.name)!
                if (!correspondingTemplateComponent.isOptional && component.sequence === '') {
                    return false
                }
            }
            return true
        }
        return false
    }, [product])

    const haveEvaluationResults = useMemo(() => {
        if (!evaluationResult) return false
        return evaluationResult.length > 0
    }, evaluationResult)

    const setUpHighlight = (position: any, index: number) => {
        if (position.includes(",")) {
            const getPositions = position.split(", ")
            if (clickThrough === null || clickThrough + 1 === getPositions.length || index != clickIndex) {
                setClickThrough(0)
                props.highlightSequence(getPositions[0])
                setClickIndex(index)
            }
            else {
                const clickNext = clickThrough + 1
                if (clickNext >= getPositions.length) {
                    setClickThrough(null)
                    props.highlightSequence(getPositions[0])
                    return
                }
                setClickThrough(clickNext)
                props.highlightSequence(getPositions[clickNext])
            }

        }
        else {
            setClickThrough(null)
            props.highlightSequence(position)
        }

    }

    return (
        <Box sx={(theme) => ({
            height: '100%', backgroundColor: 'white', overflow: 'auto', pl: 4, pr: 4,
            scrollbarWidth: 'thin',
            scrollbarColor: `${theme.palette.secondary.light} transparent`
        })} >
            <Tabs value={selectedTab} onChange={(_, v) => setSelectedTab(v)} aria-label="basic tabs example">
                <Tab label="Check sequence" {...a11yProps(0)} sx={{ ml: 'auto', mr: 'auto' }} />
                {/* Temporarly commenting out the manufacturer checker as per Kevin's request */}
                {/* <Tab label="Check specs" {...a11yProps(1)} /> */}
            </Tabs>

            <TabPanel value={selectedTab} index={0}>
                <Typography sx={{ mt: 4 }} variant="body1" textAlign='center'>
                    Sequence check
                </Typography>

                {canCheckSequence ? (
                    <Stack direction="column" spacing={2} alignItems='center' justifyContent='start' sx={{ pl: 2, pr: 2, pb: 5, height: '100%' }}>
                        <Typography variant="body2" textAlign="center" sx={{ mt: 3, mb: 3 }}>
                            We will check for occurences of cut sites, k-mers, hairpins and high GC content windows.
                        </Typography>

                        {evaluationResult && (haveEvaluationResults ? (
                            evaluationResult?.map(({ breach, position }, index: number) => {
                                if (breach && position) {
                                    return (
                                        <Typography variant="body2" textAlign="center" sx={{ pb: 1, p: 1, cursor: 'pointer', ':hover': { backgroundColor: 'lightblue' } }} onClick={() => setUpHighlight(position, index)}>
                                            {`${breach}: ${position}`}
                                        </Typography>
                                    )
                                }
                            })
                        ) : (
                            <Typography variant="body2" textAlign="center" sx={{ mt: 3, mb: 3 }}>
                                No issues were found.
                            </Typography>
                        ))}

                        <LoadingButton
                            loading={evaluatingSequence}
                            onClick={evaluateSequence}
                            disabled={sequenceUnchanged || props.constructHasTBDcomponent}
                            variant='contained'
                            sx={{ mt: 5 }}
                        >
                            Run
                        </LoadingButton>
                        {props.constructHasTBDcomponent &&
                            <Typography variant="body2" textAlign="center" sx={{ mt: 3, mb: 3 }}>
                                Sequence checker can't be run because there are components with sequences yet to be determined.
                            </Typography>}
                    </Stack>
                ) : (
                    <Typography variant="body2" textAlign="center" sx={{ mt: 10, ml: 3, mr: 3 }}>
                        Set all the sequence components to evaluate them.
                    </Typography>
                )
                }
            </TabPanel>
            {/* Temporarly commenting out the manufacturer checker as per Kevin's request */}
            {/* <TabPanel value={selectedTab} index={1}>
                <Typography sx={{ mt: 4 }} variant="body1" textAlign='center'>
                    Manufacturability specs check
                </Typography>
                {canCheckSpecs() &&
                    <Stack direction="column" spacing={2} alignItems='center' justifyContent='center' sx={{ pl: 2, pr: 2 }}>
                        <Typography variant="body2" textAlign="center" sx={{ mt: 3, mb: 3 }}>
                            This is how your product specification matches our manufacturers.
                        </Typography>
                        {manufacturers.map((manufacturer) => <ManufacturerChecked manufacturer={manufacturer} />)}
                    </Stack>
                }
                {!canCheckSpecs() &&
                    <Typography variant="body2" textAlign="center" sx={{ mt: 10, ml: 3, mr: 3 }}>
                        Set the Manufacturability specs to evaluate them.
                    </Typography>
                }
            </TabPanel> */}
        </Box >
    )
}

export default ManufacturabilityChecker
