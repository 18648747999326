import { Box, Grid, Typography } from "@mui/material"
import ProcessDash from "../ProcessPage/ProcessDash"
import ProfilePage from "../ProfilePage/ProfilePage"

function DesignerHomePage(){
    return(
        <Box justifyContent='center'>
            <Typography textAlign='center' variant="h5" sx={{ mt: 5, mb: 5}}>Manufacturer home</Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ProfilePage />
                </Grid>
                <Grid item xs={8}>
                <Typography variant="h6" textAlign="center" sx={{ mt: 5, ml:5}}>Process Dashboard</Typography>
                    <ProcessDash/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DesignerHomePage