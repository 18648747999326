import {
  Stack,
  Grid,
  Skeleton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import ProcessCard from "./ProcessCard";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Manufacturer } from "../../../models/Manufacturer";
import { getMe } from "../../../api/ManufacturerQueries";
import { Process } from "../../../models/Process";
import AddIcon from '@mui/icons-material/Add';


function ProcessDash() {
  const [loadingProcesses, setLoadingProcesses] = useState(true);
  const [processes, setProcesses] = useState<Process[]>([]);
  const { getAccessTokenSilently } = useAuth0();


  const getCurrentManufacturer = async () => {
    setLoadingProcesses(true)
    const accessToken = await getAccessTokenSilently();
    const manufacturer: Manufacturer = await getMe(accessToken);
    if (manufacturer) {
      setProcesses(manufacturer.processes)
      console.log(manufacturer.processes)
    }
    setLoadingProcesses(false)
  };

  useEffect(() => {
    getCurrentManufacturer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Stack direction="column" alignItems="center">
      <Box sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        <Link to="/process/new">
          <Button variant="contained">Create Process <AddIcon sx={{ ml: 1 }} /></Button>
        </Link>
      </Box>
      <Grid sx={{ p: 5 }} container direction="row" alignItems="center">
        {loadingProcesses
          ? Array.from(new Array(2)).map((_, index) => (
            <Grid item xs={6} key={index}>
              <Skeleton sx={{ m: 2 }} variant="rectangular" height={200} />
            </Grid>
          ))
          :
          processes.length ? processes.map((process: Process, index: number) => {
            return (
              <Grid item xs={6} key={index}>
                <ProcessCard
                  currentProcess={process}
                  processes={processes}
                  setProcesses={setProcesses}
                  template={process.template}
                />
              </Grid>
            );

          })
            :
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="body1" align="center">
                No processes found. Create a process using the button on the bottom right of the page.
              </Typography>
            </Grid>
        }
      </Grid>
    </Stack>
  );
}

export default ProcessDash;
