import { CQAReport } from "./CQAReport";
import { Designer } from "./Designer";
import { Manufacturer } from "./Manufacturer";
import { Process } from "./Process";
import { Product } from "./Product";

export enum RunStatus {
    PUBLISHED="PUBLISHED",
    DRAFTED="DRAFTED",
    RESERVED="RESERVED",
    QUEUED="QUEUED",
    IN_PROGRESS="IN_PROGRESS",
    SHIPPING_TO_BIOFORGE="SHIPPING_TO_BIOFORGE",
    BIOFORGE_QC="BIOFORGE_QC",
    SHIPPING_TO_DESIGNER="SHIPPING_TO_DESIGNER",
    COMPLETED="COMPLETED",
    CANCELED="CANCELED",
    ARCHIVED="ARCHIVED",
}

export interface Run {
    id?: string;
    name: string;
    status: RunStatus;
    description?: string;
    baseCost: number;
    dateToSubmit: Date;
    dateToStart?: Date;
    dateToShip: Date;
    formulation: boolean;
    formulationDetails?: string;
    dnaSynthesis: boolean;
    process?: Process;
    processId?: string;
    manufacturer?: Manufacturer;
    manufacturerId: string;
    designer?: Designer;
    products?: Product[];
    finalProductId?: string;
    cqaReport?: CQAReport
}
