import {
  Stack,
  Grid,
  Skeleton,
  Button,
  Box,
  Typography,
} from "@mui/material";
import RunCard from "./RunCard";
import { useAuth0 } from "@auth0/auth0-react";
import { Run } from "../../../models/Run";
import { useEffect, useState } from "react";
import { getRuns } from "../../../api/RunQueries";
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import { FilterBar } from "./FilterBar";


function BrokerDashboardPage() {
  const [runs, setRuns] = useState<Run[]>([]);
  const [filteredRuns, setFilteredRuns] = useState<Run[]>([]);
  const [filter, setFilter] = useState<string>("All")
  const [loadingRuns, setLoadingRuns] = useState(true);
  const [changedWhileFiltered, setChangedWhileFiltered] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const grabRuns = async () => {
    setLoadingRuns(true);
    const accessToken = await getAccessTokenSilently();
    const runData = await getRuns(accessToken);
    if (Array.isArray(runData)) {
      setRuns(runData);
      setFilteredRuns(runData);
    }
    setLoadingRuns(false);
  };

  useEffect(() => {
    grabRuns();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (filter !== "All") {
      const filterRuns = runs.filter((run) => run.status === filter);
      setFilteredRuns(filterRuns)
      }
    else{
      setFilteredRuns(runs)
    }
  }, [filter, runs])


  return (
    <Stack direction="column" alignItems="center">
      <FilterBar filterStatus={(e:any) => setFilter(e.target.value)} />
      <Box sx={{position: 'fixed', bottom: '20px', right: '20px'}}>
        <Link to="/create-run">
          <Button variant="contained">Create Run <AddIcon sx={{ml:1}} /></Button>
        </Link>
      </Box>
      <Grid sx={{ p: 5, pl:20, pr:20 }} container direction="row" alignItems="center">
        {loadingRuns
          ? Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={4} key={index}>
                <Skeleton sx={{m:2}} variant="rectangular" height={400} />
              </Grid>
            ))
          : filteredRuns.length > 0 && filteredRuns.map((run: Run, index:number) => {
              return (
                <Grid item sm={6} md={4} key={index}>
                  <RunCard currentRun={run} runs={runs} setRuns={setRuns} />
                </Grid>
              );
            })}
      </Grid>
      {filteredRuns.length === 0 && <Typography sx={{alignSelf: 'center'}}variant="h5">No runs found</Typography>}
    </Stack>
  );
}

export default BrokerDashboardPage;
