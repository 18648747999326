import {
  Stack,
  Grid,
  Skeleton,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Run } from "../../../models/Run";
import { useEffect, useMemo, useState } from "react";
import { getManufacturerRuns } from "../../../api/RunQueries";
import RunCard from "../../AdminPages/BrokersDashboardPage/RunCard";
import CloseIcon from '@mui/icons-material/Close';
import ProductWindow from "../../../components/ProductWindow/ProductWindow";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ReservedRunsPage() {
  const [runs, setRuns] = useState<Run[]>([]);
  const [loadingRuns, setLoadingRuns] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const [showProductModal, setShowProductModal] = useState(false);
  const [viewingProductId, setViewingProductId] = useState<string | undefined>(undefined);

  const grabRuns = async () => {
    setLoadingRuns(true);
    const accessToken = await getAccessTokenSilently();
    const runData = await getManufacturerRuns(accessToken);
    if (Array.isArray(runData)) {
      setRuns(runData.filter(run => run.status !== 'DRAFTED' && run.status !== 'PUBLISHED' && run.status !== 'RESERVED'));
    }
    setLoadingRuns(false);
  };

  useEffect(() => {
    grabRuns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onViewProduct = (productId: string) => {
    setViewingProductId(productId);
    setShowProductModal(true);
  }

  const skeletons = useMemo(() => {
    return Array.from(new Array(3)).map((_, index) => (
      <Grid item md={4} key={index}>
        <Skeleton sx={{ m: 2 }} variant="rectangular" height={'400px'} />
      </Grid>
    ))
  }, [])

  const runCards = useMemo(() => {
    return runs.map((run: Run, index: number) => {
      return (
        <Grid item sm={6} md={4} key={index}>
          <RunCard currentRun={run} runs={runs} setRuns={setRuns} forManufacturers onViewProduct={onViewProduct} />
        </Grid>
      );
    })
  }, [runs])


  return (
    <Stack direction="column" alignItems="center" spacing={3}>
      <Typography variant="h5" sx={{ mt: 5 }}>Your runs</Typography>
      <Typography variant="subtitle1">See here runs of your processes that are or were at some point processed through the portal</Typography>
      <Grid sx={{ p: 5, pl: 20, pr: 20 }} container direction="row" alignItems="center">
        {loadingRuns ? skeletons : runCards }
      </Grid>
      <Modal
        open={showProductModal}
      >
        <Box sx={modalStyle}>
          <CloseIcon sx={{ cursor: "pointer", float: "right" }} onClick={() => setShowProductModal(false)} />
          <ProductWindow idFromProps={viewingProductId!} forManufacturer/>
        </Box>
      </Modal>
    </Stack>
  );
}

export default ReservedRunsPage;
