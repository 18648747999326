import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DesignerRunCard from "./DesignerRunCard";
import { Link } from "react-router-dom";
import { getProducts } from "../../../api/ProductQueries";
import { Product } from "../../../models/Product";
import AddIcon from '@mui/icons-material/Add';

function RunsDash() {
  const [loadingRuns, setLoadingRuns] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const { getAccessTokenSilently } = useAuth0();


  const getAllRuns = async () => {
    setLoadingRuns(true);
    const accessToken = await getAccessTokenSilently();
    const productData: Product[] | undefined = await getProducts(accessToken);

    if (productData) {
      const filteredProducts = productData.filter((product: Product) => product.runIsReserved)
      setProducts(filteredProducts)
    }

    setLoadingRuns(false);
  };

  useEffect(() => {
    getAllRuns();
  }, []);


  const skeletons = useMemo(() => {
    return Array.from(new Array(2)).map((_, index) => (
      <Grid item xs={6} key={index}>
        <Skeleton sx={{ m: 2 }} variant="rectangular" height={'300px'} />
      </Grid>
    ))
  }, [])

  return (
    <Stack direction="column" alignItems="center">
      <Box sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        <Link to="/constructs">
          <Button variant="contained">Reserve a Run <AddIcon sx={{ ml: 1 }} /></Button>
        </Link>
      </Box>
      <Grid sx={{ p: 5 }} container direction="row" alignItems="center">
        {loadingRuns ? (
          skeletons
        ) : Array.isArray(products) && products.length > 0 ? (
          products.map((product: Product, index: number) => {
            if (product.run) {
              return (
                <Grid item sm={12} md={6} key={index}>
                  <DesignerRunCard
                    run={product.run}
                    product={product}
                  />
                </Grid>
              );
            }
          })
        ) :
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body1">
              No runs found. Reserve a run to see it below.
            </Typography>
          </Grid>
        }
      </Grid>
    </Stack>
  );
}

export default RunsDash;
