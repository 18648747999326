import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Stack, Box, FormControl, Select, MenuItem, InputLabel, CircularProgress, Modal, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { useAuth0 } from "@auth0/auth0-react";
import { FailureTask, OptimizationInput, StartedOrPendingTask, SuccessTask } from "../../../models/Optimization";
import { getResult } from "../../../api/OptimizeQueries";
import RefreshIcon from '@mui/icons-material/Refresh';
import { showAlert } from "../../../components/Alert/alertSlice";
import { useDispatch } from "react-redux";
import { getProductById, updateProduct } from "../../../api/ProductQueries";
import { Product } from "../../../models/Product";
import { LoadingButton } from "@mui/lab";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 7,
    borderRadius: '16px'
};

interface OptimizationReportComponentProps {
    optimizationInput: OptimizationInput
}

function OptimizationReportComponent(props: OptimizationReportComponentProps) {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()

    const [task, setTask] = useState<SuccessTask | StartedOrPendingTask | FailureTask | undefined>()
    const [loading, setLoading] = useState(false)
    const [outputSequenceIndex, setOutputSequenceIndex] = useState(0)
    const [pasteIntoProductModal, setPasteIntoProductModal] = useState(false);
    const [pastingSequence, setPastingSequence] = useState(false)
    const [product, setProduct] = useState<Product | undefined>(undefined)

    // If already an id in the params, get the task
    useEffect(() => {
        if (props.optimizationInput) {
            getResultForOptimization();
            if (props.optimizationInput.productId) {
                getProduct();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSequenceCopy = () => {
        if (task && task.status === "SUCCESS") {
            navigator.clipboard.writeText(task.result.output[outputSequenceIndex].sequence)
            dispatch(showAlert({ message: "Sequence copied to your clipboard", severity: "success" }))
        }
    };

    const getProduct = async () => {
        setLoading(true)
        const accessToken = await getAccessTokenSilently()
        const product = await getProductById(accessToken, props.optimizationInput.productId!, false)
        if (product) setProduct(product)
        setLoading(false)
    }

    const updateProductGeneOfInterest = async () => {
        setPastingSequence(true)
        if (product?.components) {
            const newProduct = { ...product }
            for (let component of newProduct.components) {
                if (component.name === "Gene of interest") {
                    const successTask = task as SuccessTask
                    const taskResult = successTask.result
                    component.sequence = taskResult.output[outputSequenceIndex].sequence
                }
            }
            delete newProduct.id

            const accessToken = await getAccessTokenSilently()
            await updateProduct(accessToken, newProduct, product!.id!)
            dispatch(showAlert({ message: "Sequence replaced in the construct", severity: "success" }))
        }
        setPastingSequence(false)
        setPasteIntoProductModal(false)
    }

    const getResultForOptimization = async () => {
        setLoading(true)
        const accessToken = await getAccessTokenSilently()
        const result = await getResult(accessToken, props.optimizationInput.id!)
        if (result) {
            setTask(result)
        }
        setLoading(false)
    }

    const getConstraintScoresText = () => {
        const successTask = task as SuccessTask
        const taskResult = successTask.result
        return (
            <Stack direction='column' spacing={1} sx={{ py: 2 }} alignItems='center'>
                {Object.entries(taskResult.output[outputSequenceIndex].score.constraint_scores).map(([constraintName, constraintScore]) => {
                    return (
                        <Stack direction='row' spacing={1} alignItems='center'>
                            {taskResult.output[outputSequenceIndex].constraint_contributions &&
                                <Tooltip
                                    title={`This constraint contributes ${taskResult.output[outputSequenceIndex].constraint_contributions![constraintName].toFixed(2)}% of its score to the total score`}
                                    placement='left'>
                                    <HelpOutlineIcon sx={{ fontSize: 15, cursor: 'pointer' }} />
                                </Tooltip>
                            }
                            <Typography variant="body2" align="center">
                                {`${constraintName}: ${(constraintScore * 100).toFixed(2)} / ${100}`}
                            </Typography>
                        </Stack>
                    )
                })}


            </Stack>
        )
    }

    const getTaskOutputComponent = () => {
        if (task) {
            if (task.status === "SUCCESS") {
                return <>
                    <Typography variant="h4" align="center">
                        Optimized sequence:
                    </Typography>
                    <TextField
                        sx={{ mt: 5 }}
                        inputProps={{ readOnly: true }}
                        label="Optimized sequence"
                        value={task.result.output[outputSequenceIndex].sequence}
                        multiline
                        fullWidth
                        minRows={5}
                        variant="outlined"
                        autoComplete="off"
                    />
                    {task.result.output[outputSequenceIndex].recommended_5_utr &&
                        <TextField
                            sx={{ mt: 3 }}
                            inputProps={{ readOnly: true }}
                            label="Recommended 5' UTR"
                            value={task.result.output[outputSequenceIndex].recommended_5_utr}
                            multiline
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                        />}
                    {task.result.output[outputSequenceIndex].recommended_3_utr &&
                        <TextField
                            sx={{ mt: 3 }}
                            inputProps={{ readOnly: true }}
                            label="Recommended 3' UTR"
                            value={task.result.output[outputSequenceIndex].recommended_3_utr}
                            multiline
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                        />}
                    <Typography variant="h5" align="center" sx={{ mt: 5 }}>
                        {`Sequence score: ${(task.result.output[outputSequenceIndex].score.total_score * 100).toFixed(2)} / ${100}`}
                    </Typography>
                    {getConstraintScoresText()}
                    <Stack
                        sx={{ mt: 3, pb: 5 }}
                        direction="row"
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent="center">

                        <FormControl size="small" sx={{ minWidth: 120 }}>
                            <InputLabel id="version-label">Version</InputLabel>
                            <Select
                                labelId="version-label"
                                label="Version"
                                value={outputSequenceIndex}
                                onChange={(e) => setOutputSequenceIndex(e.target.value as number)}
                            >

                                {task.result.output.map((output, index) => {
                                    if (index === 0) {
                                        return <MenuItem value={index}>Best</MenuItem>
                                    }
                                    return <MenuItem value={index}>#{index + 1}</MenuItem>

                                })}
                            </Select>
                        </FormControl>

                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<ContentCopyIcon />}
                            onClick={handleSequenceCopy}
                        >
                            Copy
                        </Button>

                        {product && task.status === "SUCCESS" &&
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<ContentPasteGoIcon />}
                                onClick={() => setPasteIntoProductModal(true)}
                            >
                                Paste back into construct
                            </Button>}
                    </Stack>
                </>
            }
            if (task.status === "STARTED" || task.status === "PENDING") {
                return (
                    <Stack direction='column' justifyContent='center' alignItems='center' spacing={5}>
                        <Typography variant="body1" align="center">
                            {`Your optimization task ${task.status === "STARTED" ? "has started" : "will start soon"}.
                            You will get an email once it's finished and you can come back to this page or 
                            refresh it to view the result.`}
                        </Typography>
                        <Button size="large" variant='outlined' onClick={getResultForOptimization}>
                            <RefreshIcon />
                        </Button>
                    </Stack>
                )
            }
            if (task.status === "FAILURE") {
                return <Typography variant="body1" align="center">
                    Error: {task.result.exc_message[0]}
                </Typography>
            }
        }
    }

    return (
        <Box sx={(theme) => ({
            overflowY: 'auto',
            maxHeight: '70vh',
            pr: 10, pl: 10,
            scrollbarWidth: 'thin',
            scrollbarColor: `${theme.palette.secondary.light} transparent`
        })}>
            {loading &&
                <Box display='flex' justifyContent='center'>
                    <CircularProgress />
                </Box>}
            {!loading && task &&
                <Box>
                    <Typography variant="h5" align="center" sx={{ mt: 5, mb: 2 }}>
                        Optimization status: {task.status}
                    </Typography>
                    {task.status === "SUCCESS" &&
                        <Typography variant="body1" align="center" sx={{ mb: 5 }}>
                            Execution time: {task.result.execution_time.toFixed(3)} seconds
                        </Typography>
                    }
                    {getTaskOutputComponent()}
                </Box>}
            {product &&
                <Modal
                    open={pasteIntoProductModal}
                    onClose={() => setPasteIntoProductModal(false)}
                >
                    <Box sx={modalStyle}>
                        <Stack direction='column' justifyContent='center' alignItems='center' spacing={3}>
                            <Typography variant="h6" component="h2" textAlign='center'>
                                Pase selected sequence back into construct
                            </Typography>
                            <Typography variant="body1" textAlign='center'>
                                This will replace the Gene of Interest of the construct: {product.name}.
                            </Typography>
                            <Typography variant="body1" textAlign='center'>
                                Are you sure?
                            </Typography>
                            <Stack direction='row' spacing={3}>
                                <LoadingButton
                                    loading={pastingSequence}
                                    color="primary"
                                    variant="contained"
                                    onClick={updateProductGeneOfInterest}
                                >
                                    Yes, replace it!
                                </LoadingButton>
                                <Button color="primary" variant="outlined" onClick={() => setPasteIntoProductModal(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Modal>}

        </Box>
    )
}

export default OptimizationReportComponent
